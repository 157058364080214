<template>
  <div id="mybottom" class="bottom">
    <!-- 计时器 -->
    <div class="conent">
      <p>
          <span>
            Copyright © 2022 All Rights Reserved. 华财企服(北京)科技有限公司 版权所有
          </span>
      </p>
      <p>
        <span>
           <a href="https://beian.miit.gov.cn">京ICP备2021041024号</a>
        </span>
      </p>
      <p>
        <span>
          
        </span>
      </p>
    </div>
  </div>
</template>

<script>

  export default {
  
    methods:{
      login(){
        this.$router.push({name:'login'})
      }
    }
  }
</script>

<style lang="scss" scoped>
.bottom {
  margin-top: 30px;
  width: 100%;
  height: 100px;
  background: rgba(255, 255, 255, .2);
  box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .3);
  // background-color: #2d2d2d;
  position: relative;
  bottom: 0;
  z-index: 11;
  .timer {
    position: absolute;
    right: 15px;
    color: white;
    bottom: 15px;
    font-size: 20px!important;
  }
  .conent {
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #73777a;
    font-size: 14px;
    .pic {
      display: flex;
      justify-content: center;
      img {
        width: 100px;
        margin: 0 20px;
      }
    }
     p {
      margin-top: 5px;
      text-align: center;
      span {
          line-height: 30px;
      }
    }
  }
}
</style>