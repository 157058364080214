<template>
<div>
  <!-- pc端导航 -->
  <header>
      <div class="wrapper">
        <el-row>
          <el-col :span="7">
            <div class="logo threed">华财企服(北京)科技有限公司</div>
          </el-col>
          <el-col :span="17">
            <!-- 导航菜单 -->
            <el-menu mode="horizontal" 
            :default-active="$route.path"
            @select="handleSelect"
            background-color="rgba(255, 255, 255, .0)"
            text-color="#9d9d9d"
            active-text-color="#fff"
            class="nav"
            >
              <el-menu-item index="/" @click="goToto">
                 <router-link to="/" ><i class="iconfont My-new-iconshouye"></i>首页</router-link>
              </el-menu-item>
              <el-menu-item index="/Lists" @click="goToto">
                 <router-link to="/Lists"><i class="iconfont My-new-iconzixun"></i>产品服务</router-link>
              </el-menu-item>
              <el-menu-item index="/timeLocus" @click="goToto, changeTimeShow">
                  <router-link to="/timeLocus" ><i class="iconfont My-new-icondaojishi"></i>关于我们</router-link>
              </el-menu-item>
              <el-menu-item index="/recommend" @click="goToto">
                 <router-link to="/recommend" ><i class="iconfont My-new-icondianzan"></i>联系我们</router-link>
              </el-menu-item>
              <el-menu-item v-popover:popover4 style="padding-left:15px;padding-right:15px">
                 <i class="iconfont My-new-iconerweima" style="color:#fff"></i>微信小程序
              </el-menu-item>
              <el-menu-item style="margin-left:15px;">
                 <a class="login" href="https://www.huacaiqf.com/hc-manage"><i class="iconfont My-new-iconxuanzhonghaoyou"></i>登录</a>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
  </header>
  <div id="mobileNav">
    <!-- 移动端导航 -->
      <el-row class="tac">
      <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span class="logotit">华财企服</span>
              <span v-if="UserInfo.nickname">{{UserInfo.nickname}},欢迎您</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/" @click="Goto"><i class="iconfont My-new-iconshouye"></i>博客首页</el-menu-item>
              <el-menu-item index="/Lists" @click="Goto"><i class="iconfont My-new-iconzixun"></i>技术博文</el-menu-item>
               <el-menu-item index="/timeLocus" @click="Goto"><i class="iconfont My-new-icondaojishi"></i>时间轨迹</el-menu-item>
              <el-menu-item index="/recommend" @click="Goto"><i class="iconfont My-new-icondianzan"></i>博客推荐</el-menu-item>
              <el-menu-item index="/aboutMe" @click="Goto"><i class="iconfont My-new-iconwode1"></i>关于我</el-menu-item>
               <el-menu-item  index="/LeaveWord" @click="Goto"><i class="iconfont My-new-iconbianji"></i>留言</el-menu-item>
              <el-menu-item index="/personal" v-if="UserInfo.nickname" @click="Goto"><i class="iconfont My-new-iconwode1"></i>{{UserInfo.nickname}}的个人中心</el-menu-item>
              <el-menu-item  index="/login" v-else @click="Goto">登录</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-row>
  </div>

  <el-popover
    ref="popover4"
    placement="bottom"
    width="240"
    transition="fade-in-linear"
    trigger="click">
    <div style="text-align:center">
    <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/wxCode.png">
      嘉旺
    </div>
    <div style="text-align:center">
    <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/hcWxCode.jpg">
      财达
    </div>
  </el-popover>
  
</div>
    
</template>

<script>
  export default {
    data() {
      return {
        UserInfo:{},
        nickname:''
      }
    },
    methods: {
      // 修改当前活跃
        handleSelect(index) {
          this.activeIndex = index + ''
        },
      goToto(){
        this.$emit('goTo')
      },
      goLogin(){
        this.$router.push('/login')
        // window.location.href = '/login'
        // this.$router.go(0)
        // window.location.reload();
      },
      // 获取用户信息
      // GetInfo(){
      //    this.$http.get('/api/users/info').then( (res) => {
      //      console.log(res);
      //      this.UserInfo = res.data.data
      //      this.nickname = res.data.data.nickname
      //      let avatar = res.data.data.head_img
      //     //  获取用户昵称保存在 sessionStorage
      //     sessionStorage.setItem("avatar", avatar);
      //      sessionStorage.setItem("nickname", this.nickname);
      //     //  console.log(this.UserInfo);
      //    })
      // },
      // 移动端导航
      handleOpen(key, keyPath,index) {
        console.log(key, keyPath);
        console.log(index);
      },
      handleClose(key, keyPath,index) {
        console.log(key, keyPath);
        console.log(index);
      },
      Goto(){
        let but = document.querySelector('.el-submenu__title');
        but.click();
      },
      changeTimeShow(){
        this.$store.commit('changeTimeLocus', 1)
      }
    },
    computed:{
      isSignIn(){
        return localStorage.getItem('isLoginStatus')
      },
      nameNick(){
        return localStorage.getItem('nickname')
      }
    },
    created(){
      
      
    },
    updated(){
      
    },
    mounted() {
      this.activeIndex = window.location.href.split('/')[4]
    }

  }
</script>

<style lang="scss" scoped>
.el-row {
  background-color:rgba(255, 255, 255, .0)
}
header {
      background: rgba(255, 255, 255, .1);
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .3);
  // background-color:#2d2d2d;
  // box-shadow: 1px 1px 1px 1px rgb(41, 40, 40);
  .logo {
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  }
  .nav {
    float:right;
    li {
      padding: 0;
    }
    a {
      padding: 20px;
      .iconfont {
        color: #fff;
        // 垂直居中
        vertical-align: top;
        margin: 0 5px 0 0 ;
      }
    }
    .login {
      background: rgb(19, 167, 226);
      color: #fff;
      height: 100%;
      margin: 0;
    }
  }
}

i {
  margin-right: 8px;
}


.el-menu.el-menu--horizontal {
   border-bottom: 0
}

</style>