<template>
  <div class="commonLayout">
    <!-- 导航条 -->
    <nav>
      <common-header @goTo="goTo()" class="wow slideInRight common-header" style="top:0px"></common-header>
    </nav>


      <!-- 中心 -->
      <section v-bind:style="{minHeight: Height+'px'}">
        <div class="marginTop">
            <el-row :gutter="10" class="head" v-show="!isShowDiv">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple slideshowBox">
                  <!-- 视图切换 -->
                    <article ref="article">
                      <keep-alive include="timeLocus">
                        <router-view></router-view>
                      </keep-alive>
                    </article>
                </div>
                </el-col>
             
            </el-row>
            <!-- 动画效果展示  -->
            <animation  v-if="isShowDiv"></animation>
        </div>
      </section>

    <!-- 底部 -->
    <footer>
      <common-bottom></common-bottom>
    </footer>
  </div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader.vue'
import CommonBottom from './CommonBottom.vue'
import axios from 'axios'
export default {
  name: 'commonLayout',
  components:{
    CommonHeader,
    CommonBottom,
    Animation
  },
  data() {
    return {
      isShowDiv:false,
      Height:0,
      location: '武汉',
      visit: 0,
      boximg:'https://img1.baidu.com/it/u=30571864,657645104&fm=26&fmt=auto',
      boxShow:true,
      gb:false
    }
  },
  methods: {
    goTo(){
      this.isShowDiv = false
    },
    showTransition(){
      this.isShowDiv = true;
      window.pageYOffset = document.documentElement.scrollTop = 0;
    },
    changeBoxShow(){
      this.boxShow = false
    },
    inclick(){
      this.gb = true
    },
    outclick(){
      this.gb = false
    }
  },
  mounted(){
    this.Height = document.documentElement.clientHeight-210;  
　　//监听浏览器窗口变化　
    window.onresize = ()=> {this.Height = document.documentElement.clientHeight-300};
    
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.commonLayout {
  position:absolute;
    top:0;
    left:0;
   width: 100%;
   height: 100%;
  // 导航条
    .common-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    flex-direction: column;
        min-height: 100%;
  }
  // 切换部分
  section {
    margin-top: 80px;
    .slideshowBox {
       margin-right: 16px;
       margin-left: 16px;
    }   
  }
  // 侧边栏
  aside {
    width: 100%;
    .maginbot {
      margin-bottom: 20px;
      width: 100%;
    }
    .weather {
      font-family: 'Courier New', Courier, monospace;
      color: black;
      height: 255px;
      // background: url('https://img2.baidu.com/it/u=384710098,2012479534&fm=26&fmt=auto&gp=0.jpg');
      background: rgba(255, 255, 255, .7);
      background-position: center;
      background-size: 100%;
      z-index: 3;
      margin-bottom: 10px;
      & .itemtext{
        font-size: 20px;
        margin: 3px;
      }
      & .smalltext{
        font-size: 18px;
        .smalltext p{
          margin: 5px;
        }
      }
      .weatherheader{
        // display: flex;
        // justify-content: space-around;
        margin-bottom: 10px;
      }
      .locationtext{
        font-size: 20px;
      }
      .item {
        position: relative;
        background:hsla(0, 0%, 100%, .5);
        box-shadow: 0 0 15px rgb(44, 44, 44);
        z-index: 99;
        // background:hsla(hue, saturation, lightness, alpha);
      }
      .item::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        filter: blur(20px);
        
      }
    }
  }
}

.showTransition {
  cursor:pointer;
  text-align: center;
  font-weight: 600;
}
.weatherfoot{
  text-align: end;
  margin-top: 20px;
}
.box{
  position: relative;
}

  #x {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 25px;
  color: red;
  background-color: #f1f1f1;
  cursor: pointer;
}


</style>
